import React, { useState, useEffect } from 'react';
import { bedroom4, reception1, reception2, reception3 } from '../images';

// تعريف النوع لمصادر الصور
type ImageSources = {
  [key in 'reception1' | 'reception2' | 'reception3' | 'bedroom4']: string;
};

const imageSources: ImageSources = {
  reception1,
  reception2,
  reception3,
  bedroom4,
};

const loadingText = 'جارٍ التحميل...';

const ResponsiveComponent: React.FC = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // تحميل جميع الصور
    const imgPromises = Object.values(imageSources).map(src => {
      return new Promise<boolean>((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false); // التعامل مع أخطاء تحميل الصورة
      });
    });

    Promise.all(imgPromises).then(results => {
      // التحقق من تحميل جميع الصور بنجاح
      setImageLoaded(results.every(result => result));
    });
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 relative h-[95vh]  rtl max-2xl:h-[900px]">
      <div className="col-span-1 grid grid-cols-2 gap-2 m-4 md:m-8">
        {Object.keys(imageSources).map((key) => (
          <div key={key} className="bg-primary h-full w-full border border-gray-200 rounded-lg shadow-lg">
            {imageLoaded ? (
              <img
                src={imageSources[key as keyof ImageSources]} // تأكيد نوع المفتاح
                alt={`صورة ${key}`}
                className="h-[50vh] w-[50vh] object-cover rounded-lg"
              />
            ) : (
              <p className="text-center text-white">{loadingText}</p>
            )}
          </div>
        ))}
      </div>
      <div className="bg-primary col-span-1 grid grid-cols-1 md:grid-cols-3 gap-4 p-4 md:p-8 ">
        <div className="bg-secondary col-span-1 md:col-span-3 flex flex-col justify-center items-center text-primary p-4 md:p-8 shadow-2xl rounded-lg border border-gray-300">
          <h1 className="text-xl md:text-3xl font-bold bg-[#5a61376a] px-4 py-1 text-30-percent mb-4 text-center rounded-lg shadow-lg">
            اكتشف قوة التصميم ثلاثي الأبعاد والواقع الافتراضي مع Support Constructions! 🌟
          </h1>
          <ul className="text-lg md:text-xl font-bold space-y-4">
            <li className="p-4  rounded-lg shadow-md border border-gray-200">
              <h3 className="text-30-percent">تصميم ثلاثي الأبعاد مميز 🎨</h3>
              <p className="text-primary mt-2">
                بفضل استخدامنا لتقنيات التصميم ثلاثي الأبعاد، يمكننا تقديم تصور دقيق لمشاريعك قبل البدء الفعلي. هذا يمكن أن يساعدك على تصور التفاصيل بشكل أفضل وضمان تحقيق رؤيتك بشكل كامل.
              </p>
            </li>
            <li className="p-4  rounded-lg shadow-md border border-gray-200">
              <h3 className="text-30-percent">معاينة الواقع الافتراضي 🕶️</h3>
              <p className="text-primary mt-2">
                قبل بدء أي مشروع، نقدم لك فرصة لمعاينة تصاميمنا في بيئة الواقع الافتراضي. هذا يتيح لك التفاعل مع المشروع بشكل مباشر، وإجراء التعديلات اللازمة قبل التنفيذ الفعلي.
              </p>
            </li>
            <li className="p-4  rounded-lg shadow-md border border-gray-200">
              <h3 className="text-30-percent">التعاون الوثيق مع العملاء 🤝</h3>
              <p className="text-primary mt-2">
                نعمل يدًا بيد مع عملائنا في كل خطوة من خطوات المشروع. من تصميم الأفكار إلى تنفيذ التفاصيل، نحن هنا لضمان تحقيق كل عنصر وفقًا لرؤيتك ومتطلباتك.
              </p>
            </li>
          </ul>
        </div>
      </div>
      
    </div>
  );
};

export default ResponsiveComponent;
