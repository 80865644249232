import React, { useEffect, useRef } from "react";
import "./mision.css";
import { reception1, toilet1 } from "../images";

const OurMissionSection: React.FC = () => {
  const elementsRef = useRef<(HTMLDivElement | null)[]>([]); // Ref array for each element

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in-visible");
            observer.unobserve(entry.target); // Unobserve after the element becomes visible
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    elementsRef.current.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elementsRef.current.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <section className="w-screen h-screen bg-30-percent relative" dir="rtl">
      <div
        ref={(el) => (elementsRef.current[0] = el)}
        className="fade-in-hidden bg-primary w-96 max-sm:w-44 h-[1px] shadow-2xl absolute left-0 top-12"
      ></div>
      <div
        ref={(el) => (elementsRef.current[1] = el)}
        className="fade-in-hidden h-[1px] shadow-2xl absolute ml-16 left-96 max-sm:left-40 top-9 text-white"
      >
        من نحن
      </div>
      <div
        ref={(el) => (elementsRef.current[2] = el)}
        className="fade-in-hidden bg-primary w-1/6 h-1/2 max-sm:h-[200px] max-sm:w-[120px] shadow-2xl absolute right-12 max-sm:right-1 top-0"
      >
        <img
          src={toilet1}
          alt="Centered Image"
          className="h-full w-full object-cover slow-zoom-animation"
        />
      </div>
      <div
        ref={(el) => (elementsRef.current[3] = el)}
        className="fade-in-hidden bg-primary w-1/2 h-96 shadow-2xl absolute left-12 top-44"
      >
        <img
          src={reception1}
          alt="Centered Image"
          className="h-full w-full object-cover slow-zoom-animation"
        />
      </div>
      <div
        ref={(el) => (elementsRef.current[4] = el)}
        className="fade-in-hidden bg-secondary w-2/5 max-sm:w-fit max-sm:h-fit h-64 shadow-2xl absolute  left-[45vw] top-96 p-4  text-white"
      >
        شركة Support Constructions تتمتع بخبرة عميقة في تصميم وتنفيذ الديكورات
        الحديثة. نحرص على استغلال المساحات بشكل مثالي، سواء كانت صغيرة أو كبيرة،
        لضمان تحقيق التميز في مشاريعك. مع التزامنا بتقديم كل ما هو جديد ومناسب،
        نوفر لك الوقت والجهد والميزانية. في Support Constructions، نركز على
        تصميمات داخلية عصرية تلبي احتياجات عملائنا. فريقنا المتخصص يضمن تنفيذ
        مجموعة متنوعة من الأساليب بإتقان، ليعكس منزلك أو مشروعك التجاري ذوقك
        الفريد ويحقق توازنًا وأناقة متكاملة.
      </div>
    </section>
  );
};

export default OurMissionSection;
