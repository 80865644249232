// src/pages/LoginPage.tsx
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Alert from '../components/Alert'; // Ensure this component is created
import { image1 } from '../images';

const LoginPage = () => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    // Clear the error message when the user starts typing
    const handleInputChange = () => {
      if (error) {
        setError(null);
      }
    };

    // Attach event listeners to input fields
    const emailInput = document.getElementById('email');
    const passwordInput = document.getElementById('password');

    emailInput?.addEventListener('input', handleInputChange);
    passwordInput?.addEventListener('input', handleInputChange);

    // Clean up event listeners on component unmount
    return () => {
      emailInput?.removeEventListener('input', handleInputChange);
      passwordInput?.removeEventListener('input', handleInputChange);
    };
  }, [error]);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Clear previous errors

    try {
      const { success } = await login(email, password);

      if (success) {
        navigate('/dashboard'); // Redirect to dashboard if login is successful
      } else {
        setError(t('loginFailed') || 'Login failed');
      }
    } catch (error) {
      console.error('Login failed', error);
      setError(t('loginFailed') || 'Login failed');
    }
  };

  return (
    <>
      <div className="min-w-screen min-h-screen bg-[#5a6137] flex items-center justify-center px-5 py-5">
        <div className="bg-[#ffffff] text-[#5a6137] rounded-3xl shadow-xl w-full overflow-hidden" style={{ maxWidth: '1000px' }}>
          <div className="md:flex w-full">
            <div className="hidden md:block w-1/2 bg-[#aeaa87] ">
            <img
              className="object-cover object-center w-full h-full "
              src={image1} // Replace with your image path
              alt="support construction "
            />
            </div>
            <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
              <div className="text-center mb-10">
                <h1 className="font-bold text-3xl text-[#5a6137]">{t('login')}</h1>
                <p>{t('enterCredentials')}</p>
              </div>
              {error && <Alert message={error} type="error" />}
              <form onSubmit={handleSubmit}>
                <div className="flex -mx-3">
                  <div className="w-full px-3 mb-5">
                    <label htmlFor="email" className="text-xs font-semibold px-1">{t('email')}</label>
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <i className="mdi mdi-email-outline text-gray-400 text-lg"></i>
                      </div>
                      <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-[#aeaa87] outline-none focus:border-[#5a6137]"
                        placeholder={t('emailPlaceholder') || "johnsmith@example.com"} // Translatable placeholder
                      />
                    </div>
                  </div>
                </div>
                <div className="flex -mx-3">
                  <div className="w-full px-3 mb-12">
                    <label htmlFor="password" className="text-xs font-semibold px-1">{t('password')}</label>
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <i className="mdi mdi-lock-outline text-gray-400 text-lg"></i>
                      </div>
                      <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-[#aeaa87] outline-none focus:border-[#5a6137]"
                        placeholder={t('passwordPlaceholder') || "************"} // Translatable placeholder
                      />
                    </div>
                  </div>
                </div>
                <div className="flex -mx-3">
                  <div className="w-full px-3 mb-5">
                    <button type="submit" className="block w-full max-w-xs mx-auto bg-[#5a6137] hover:bg-[#3c4b29] text-white rounded-lg px-3 py-3 font-semibold">
                      {t('login')}
                    </button>
                  </div>
                </div>
              </form>
              <div className='text-center align-middle flex flex-col'>
                <h2>{t('noAccount')}</h2>
                <Link to={'/register'} className='block w-full max-w-xs mx-auto border-[#5a6137] hover:border-[#3c4b29] rounded-lg px-3 py-3 font-semibold border-[1px solid]'>
                  {t('register')} {'>>'}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-end justify-end fixed bottom-0 right-0 mb-4 mr-4 z-10">
        <div>
          <Link
            title="go to hime"
            to={'/'}
            rel="noopener noreferrer"
            className="block w-16 h-16 rounded-full transition-all shadow hover:shadow-lg transform hover:scale-110 hover:rotate-12"
          >
            <img
              className="object-cover object-center w-full h-full rounded-full"
              src={image1} // Replace with your image path
              alt="go to home"
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
