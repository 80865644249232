import React, { useState, useEffect } from 'react';
import '../utilies/AboutUsPage.css'; // Adjust the path if necessary
import CompanyHistory from './CompanyHistory';

interface RotatingImagePreviewProps {
  images: string[];
  names: string[];
  rotationDuration?: number; // in seconds, default to 4 if not provided
}

const RotatingImagePreview: React.FC<RotatingImagePreviewProps> = ({ images, names, rotationDuration = 4 }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [fadeState, setFadeState] = useState<'fade-in' | 'fade-out'>('fade-in');

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeState('fade-out'); // Start fade-out animation

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFadeState('fade-in'); // Start fade-in animation after fade-out completes
      }, 1000); // Match the duration of fade-out animation
    }, rotationDuration * 1000);

    return () => clearInterval(interval);
  }, [images.length, rotationDuration]);

  // Compute positions for indicators
  const computeIndicatorStyles = (index: number) => {
    const angle = (index / images.length) * 360;
    return {
      transform: `rotate(${angle}deg) translate(100px) rotate(-${angle}deg)`, // Adjust radius as needed
    };
  };

  const previousIndex = (currentIndex - 1 + images.length) % images.length;
  const nextIndex = (currentIndex + 1) % images.length;

  return (
    <div className="flex items-center justify-center min-h-screen w-screen bg-primary relative">
      <div className="animated-circle w-80 h-80 bg-30-percent rounded-full relative flex items-center justify-center">
        <div className="w-2 h-2 bg-primary absolute rounded-full z-10"></div>
        <div className="w-[0.1rem] h-[9.9rem] bg-primary absolute rotate-line origin-bottom top-0 z-10"></div>
        <img
          src={images[currentIndex]}
          alt="Preview"
          className={`absolute w-72 h-72 object-cover rounded-full z-20 ${
            fadeState === 'fade-in' ? 'custom-fade-in' : 'custom-fade-out'
          }`}
        />
      </div>
      {/* <CompanyHistory /> */}
      <div className={`absolute right-28 top-100 text-[3.52rem] font-bold z-10 flex flex-col text-right max-sm:hidden ${
        fadeState === 'fade-in' ? 'custom-fade-in' : 'custom-fade-out'
      }`}>
        <h1>{names[currentIndex]}</h1>
        <h1 className='text-sm text-gray-600'>
          فيلا مستر محمد فالتجمع مميزة بالاطلالة الخضراء
        </h1>
      </div>
      <div className="absolute left-28 top-100">
        <div className="circular-container">
          <div className="circular-indicator">
            {images.map((_, index) => (
              <div
                key={index}
                className={`indicator ${
                  index === currentIndex
                    ? 'current'
                    : index === previousIndex
                    ? 'previous'
                    : index === nextIndex
                    ? 'next'
                    : ''
                }`}
                style={computeIndicatorStyles(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RotatingImagePreview;
