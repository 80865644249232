import React, { useState, useEffect } from 'react';
import { reception1 } from '../images';

// Sample data variables
const loadingText = 'Loading...'; // Replace with your loading text if needed

const GuaranteeSection: React.FC = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = reception1;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <div className="grid grid-rows-2 relative h-[120vh] max-sm:h-[160vh] max-sm:mt-[30rem] rtl" id="last-projects">
      <div className="row-span-1 bg-black relative">
        <div className="bg-[#aeaa8729] absolute h-full w-full left-0 top-0"></div>
        {imageLoaded ? (
          <img
            src={reception1}
            alt="Loaded"
            className="w-full object-cover h-full"
          />
        ) : (
          <p>{loadingText}</p>
        )}
      </div>
      <div className="row-span-1">
        <div className="bg-secondary h-full max-sm:h-fit  w-[80%] m-auto -translate-y-32 shadow-2xl gap-7 rounded-sm text-white flex flex-col items-center justify-center p-8">
          <h1 className="text-3xl md:text-4xl font-bold mb-4 text-center">
            🛠️ ضمان الجودة في التشييد 🏆
          </h1>
          <p className="text-center text-lg md:text-xl mx-4 md:mx-8 leading-relaxed">
            نحن في <strong>support constructions</strong> نضمن لك أعلى معايير الجودة في
            جميع مشاريع البناء والتشييد. 🏗️ نلتزم بتوفير أعمال تتميز بالدقة
            والإتقان، حيث يتم فحص كل مرحلة من مراحل العمل لضمان تحقيق أفضل
            النتائج.
            <br />
            نعمل على توفير ضمانات قوية للتأكد من رضاك التام عن النتائج. مع
            دعمنا، ستستمتع بتجربة بناء خالية من المتاعب وبنتائج تتجاوز
            توقعاتك.
            <br />
            انضم إلينا اليوم واكتشف كيف يمكننا تقديم خدمة متميزة تلبي
            احتياجاتك بدقة واحترافية عالية. 🚀🔨
            <br />
            ضمان الجودة لدينا هو التزامنا نحوك لتحقيق أقصى درجات التفوق
            والرضا. 🌟👷‍♂️
          </p>
        </div>
      </div>
      {/* Other content here */}
    </div>
  );
};

export default GuaranteeSection;
