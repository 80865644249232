import React from "react";
import { reception1, reception2, reception3 } from "../../images";

interface ChooseOptionProps {
  onSelectOption: (option: "preview" | "help") => void;
}

const items = [
  { title: "Support Constructions", image: reception3 },
  { title: "Outdoor Design", image: reception2 },
  { title: "Interior Design", image: reception1 },
];

const ChooseOption: React.FC<ChooseOptionProps> = ({ onSelectOption }) => {
  return (
    <div className="flex flex-col items-center justify-center h-fit mt-24 mb-8">
      {/* Header Section */}
      <h1 className="text-3xl font-bold mb-8 text-center relative z-10 bg-white/70 py-2 px-6 rounded-md shadow-md backdrop-blur-lg">
        كيف يمكننا مساعدتك؟
      </h1>

      {/* Options Section */}
      <div className="flex space-x-6 max-sm:flex-col max-sm:space-y-4 mt-8">
        <OptionCard
          title="مشاهدة اعمالنا"
          onClick={() => onSelectOption("preview")}
          icon={
            <svg
              className="w-16 h-16 text-secondary animate-bounce"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 20l9-5-9-5-9 5 9 5z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 12l9-5-9-5-9 5 9 5z"
              />
            </svg>
          }
          buttonClass="bg-secondary hover:bg-secondary/90"
        />

        <OptionCard
          title="طلب الخدمة"
          onClick={() => onSelectOption("help")}
          icon={
            <svg
              className="w-16 h-16 text-green-500 animate-bounce"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          }
          buttonClass="bg-green-500 hover:bg-green-600"
        />
      </div>
    </div>
  );
};

interface OptionCardProps {
  title: string;
  onClick: () => void;
  icon: React.ReactNode;
  buttonClass: string;
}

const OptionCard: React.FC<OptionCardProps> = ({ title, onClick, icon, buttonClass }) => {
  return (
    <div className="max-w-sm rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300">
      <div className="p-6 text-center">
        <div className="flex justify-center items-center mb-4">{icon}</div>
        <button
          onClick={onClick}
          className={`${buttonClass} text-white py-2 px-6 rounded-md transition-transform transform hover:scale-105`}
        >
          {title}
        </button>
      </div>
    </div>
  );
};

export default ChooseOption;
