import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  image1,
  section1,
  reception1,
  reception2,
  reception3,
  bedroom1,
  bedroom2,
  bedroom3,
  bedroom4,
  bedroom5,
  bedroom6,
  bedroom7,
  bedroom8,
  bedroom9,
  toilet1,
  toilet2,
  toilet3,
  toilet4,
  toilet5,
  toilet6,
  toilet7,
  toilet8,
  hall1,
  kitchen1,
  kitchen2,
} from "../images";

interface ImageData {
  src: string;
  alt: string;
  name: string;
}

const images: ImageData[] = [
  {
    src: reception1,
    alt: "Sleek and modern reception area of Support Constructions' office",
    name: "Support Constructions Reception Area",
  },
  {
    src: reception2,
    alt: "Welcoming and inviting lobby of Support Constructions' headquarters",
    name: "Support Constructions Lobby",
  },
  {
    src: reception3,
    alt: "Elegant reception counter at the entrance of Support Constructions' office",
    name: "Support Constructions Reception Desk",
  },
  {
    src: bedroom1,
    alt: "Comfortable and functional meeting room at Support Constructions' office",
    name: "Support Constructions Meeting Room",
  },
  {
    src: bedroom2,
    alt: "Spacious and well-equipped conference room for Support Constructions' team",
    name: "Support Constructions Conference Room",
  },
  {
    src: bedroom3,
    alt: "Bright and airy collaborative workspace at Support Constructions' office",
    name: "Support Constructions Collaborative Workspace",
  },
  {
    src: bedroom5,
    alt: "Cozy and inviting breakroom for Support Constructions' employees",
    name: "Support Constructions bedroom",
  },
  {
    src: bedroom6,
    alt: "Dedicated training room at Support Constructions' headquarters",
    name: "Support Constructions bedroom",
  },
  {
    src: bedroom7,
    alt: "Sophisticated and modern office space for Support Constructions' management team",
    name: "Support Constructions bedroom",
  },
  {
    src: bedroom8,
    alt: "Ergonomic and well-designed workstations at Support Constructions' open office",
    name: "Support Constructions bedroom",
  },
  {
    src: bedroom9,
    alt: "Spacious and well-equipped project planning room at Support Constructions' office",
    name: "Support Constructions Project bedroom",
  },
  {
    src: toilet1,
    alt: "Clean and modern employee restrooms at Support Constructions' headquarters",
    name: "Support Constructions Employee toilet",
  },
  {
    src: toilet3,
    alt: "Luxurious and well-appointed executive restrooms at Support Constructions' office",
    name: "Support Constructions Executive toilet",
  },
  {
    src: toilet4,
    alt: "Sleek and contemporary guest restrooms at Support Constructions' office",
    name: "Support Constructions Guest toilet",
  },
  {
    src: toilet5,
    alt: "Spacious and accessible restroom facilities at Support Constructions' office",
    name: "Support Constructions toilet",
  },
  {
    src: toilet6,
    alt: "Bright and airy restroom with modern fixtures at Support Constructions' headquarters",
    name: "Support Constructions toilet",
  },
  {
    src: toilet7,
    alt: "Elegant and luxurious executive restroom at Support Constructions' office",
    name: "Support Constructions toilet",
  },
  {
    src: toilet8,
    alt: "Functional and well-designed employee restrooms at Support Constructions' office",
    name: "Support Constructions toilet",
  },
  {
    src: hall1,
    alt: "Inviting and well-lit hallway at Support Constructions' headquarters",
    name: "Support Constructions  Hallway",
  },
  {
    src: kitchen1,
    alt: "Modern and fully-equipped kitchen for Support Constructions' employees",
    name: "Support Constructions  Kitchen",
  },
  {
    src: kitchen2,
    alt: "Spacious and organized catering kitchen at Support Constructions' office",
    name: "Support Constructions  Kitchen",
  },
];

const SwipeIn: React.FC = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionClass, setTransitionClass] = useState("opacity-100");

  useEffect(() => {
    const interval = setInterval(() => {
      setTransitionClass("opacity-0");
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setTransitionClass("opacity-100");
      }, 500); // Match this duration with the CSS transition duration
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div className="relative h-[85vh] max-h-[85vh] bg-30-percent flex flex-col max-sm:max-w-[98vw]">
      <div className="relative h-full flex items-center justify-center">
        <div className="relative w-full h-full overflow-hidden">
          <img
            src={images[currentIndex].src}
            alt={images[currentIndex].alt}
            className={`w-full h-[83vh] object-cover transition-opacity duration-500 ease-in-out ${transitionClass} max-h-[83vh]`}
          />
          <h2
            className={`absolute inset-0 text-center text-[100px] uppercase  bg-[#a7a3a358] text-30-percent rotate-90 left-0 top-0 w-fit h-fit my-auto transform transition-transform duration-500 ease-in-out`}
          >
            {images[currentIndex].name.split(" ").slice(-1)[0]}
          </h2>
        </div>
      </div>

      <div className="flex justify-center items-center mt-4 space-x-2 -translate-y-12 ">
        {images.map((_, index) => (
          <button
            key={index}
            className={`w-4 h-4 rounded-full  shadow-xl ${
              currentIndex === index ? "bg-blue-500" : "bg-gray-300"
            } transition-colors duration-300`}
          />
        ))}
      </div>
    </div>
  );
};

export default SwipeIn;
