import React, { useState } from 'react';
import ChooseOption from './ChooseOption';
import PreviewWorks from './PreviewWorks';
import AskForHelp from './AskForHelp';
import { TextField, Button } from '@mui/material';

const Villa: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<'preview' | 'help' | 'work' | null>(null);
  
  // State for minimal forms
  const [name, setName] = useState('');
  const [problem, setProblem] = useState('');
  const [projectDetails, setProjectDetails] = useState('');

  const handleSelectOption = (option: 'preview' | 'help' | 'work') => {
    setSelectedOption(option);
  };

  const handleWhatsAppSubmit = (message: string) => {
    const whatsappUrl = `https://wa.me/201003234531?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleHelpSubmit = () => {
    const message = `مرحباً، اسمي ${name}. لدي مشكلة: ${problem}.`;
    handleWhatsAppSubmit(message);
  };

  const handleWorkSubmit = () => {
    const message = `مرحباً، اسمي ${name}. تفاصيل المشروع: ${projectDetails}.`;
    handleWhatsAppSubmit(message);
  };

  return (
    <div className="h-fit flex items-center justify-center max-w-[99vw] mx-auto mb-2 py-2 mt-32">
      {!selectedOption && <ChooseOption onSelectOption={handleSelectOption} />}

      {selectedOption === 'preview' && (
        <PreviewWorks
          onRequestHelp={() => handleSelectOption('help')}
          onRequestWork={() => handleSelectOption('work')}
        />
      )}

      {selectedOption === 'help' && (
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-4 text-center">طلب المساعدة</h2>
          <TextField
            label="اسمك"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label="معلومات اضافية"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={problem}
            onChange={(e) => setProblem(e.target.value)}
            required
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleHelpSubmit}
            className="mt-4"
          >
            إرسال إلى WhatsApp
          </Button>
        </div>
      )}

      {selectedOption === 'work' && (
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-4 text-center">طلب العمل على مشروعك</h2>
          <TextField
            label="اسمك"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label="تفاصيل المشروع"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={projectDetails}
            onChange={(e) => setProjectDetails(e.target.value)}
            required
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleWorkSubmit}
            className="mt-4"
          >
            إرسال إلى WhatsApp
          </Button>
        </div>
      )}
    </div>
  );
};

export default Villa;
