import React from 'react';
import { Link } from 'react-router-dom';
import { footerData } from '../utilies/footerData';

const Footer: React.FC = () => {
  return (
    <footer className="bg-secondary dark:bg-secondary mt-48 max-sm:mt-[45rem]">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
          {footerData.map((section: FooterLink) => (
            <div key={section.title}>
              <h2 className="mb-6 text-sm font-semibold text-10-percent uppercase dark:text-white">
                {section.title}
              </h2>
              <ul className="text-10-percent dark:text-30-percent font-medium">
                {section.links.map((link) => (
                  <li key={link.name} className="mb-4">
                    <Link to={link.href} className="hover:underline">
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="px-4 py-6 bg-gray-100 dark:bg-gray-700 md:flex md:items-center md:justify-between rtl">
          <span className="text-sm text-10-percent dark:text-gray-300 sm:text-center">
            © 2024 <Link to="/" className="hover:underline rtl">كود أوشن</Link>. جميع الحقوق محفوظة.
          </span>
          <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
            {/* Social Media Links */}
            <a href="#" className="text-30-percent hover:text-10-percent dark:hover:text-white">
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd"/>
              </svg>
              <span className="sr-only">صفحة الفيسبوك</span>
            </a>
            <a href="#" className="text-30-percent hover:text-10-percent dark:hover:text-white">
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                <path fillRule="evenodd" d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.17 4.17 0 0 0 3.867 2.877 8.291 8.291 0 0 1-5.097 1.76c-.333 0-.662-.02-.986-.06a11.704 11.704 0 0 0 6.314 1.842c7.572 0 11.732-6.267 11.732-11.662 0-.18-.004-.357-.01-.535A8.236 8.236 0 0 0 20 1.892Z" clipRule="evenodd"/>
              </svg>
              <span className="sr-only">صفحة إنستجرام</span>
            </a>
            <a href="#" className="text-30-percent hover:text-10-percent dark:hover:text-white">
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 16">
                <path d="M1.947 0h18.105C21.055 0 22 1.044 22 2.334v11.332c0 1.292-.946 2.335-2.948 2.335H1.947C.935 16 0 14.957 0 13.667V2.334C0 1.044.946 0 1.947 0Zm.117 2.5v11.67h18.1V2.5H2.064Zm9.176 2.883v5.51c0 .484-.4.88-.892.88-.497 0-.894-.396-.894-.88v-5.51c0-.486.397-.88.892-.88.492 0 .892.394.892.88Zm-4.413 0v5.51c0 .484-.4.88-.892.88a.892.892 0 0 1-.894-.88v-5.51c0-.486.397-.88.892-.88.492 0 .892.394.892.88Zm6.063 0v5.51c0 .484-.4.88-.892.88a.892.892 0 0 1-.892-.88v-5.51c0-.486.4-.88.892-.88a.892.892 0 0 1 .892.88Z"/>
              </svg>
              <span className="sr-only">قناة يوتيوب</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
