import React from "react";
import { useImageLoader } from "../hooks/useImageLoader";
import { useTranslation } from "react-i18next";
import {
  bedroom1,
  bedroom2,
  bedroom3,
  bedroom4,
  bedroom6,
  bedroom7,
  hall1,
  image1,
  kitchen1,
  kitchen2,
  reception1,
  reception2,
  reception3,
  section1,
  toilet1,
  toilet2,
  toilet3,
  toilet4,
  toilet5,
  toilet6,
  toilet7,
  video1,
} from "../images";
import SwipeIn from "../utilies/swopper";
import { url } from "inspector";
import Packages from "../utilies/Packages";
import SectionOneSwiper from "../utilies/SectionOneSwiper";
import ImageVideoPreview from "../utilies/ImageVideoPreview ";
import DiscoverSection from "../utilies/DiscoverSection";
import GuaranteeSection from "../utilies/GuaranteeSection";
import ResponsiveComponent from "../utilies/ResponsiveComponent";

const Home: React.FC = () => {
  const { t } = useTranslation();
  const imageLoaded = useImageLoader(image1);
  const items = [
    { title: "support constructions ", image: reception3 },
    { title: "outdoor  design", image: reception2 },
    { title: "interior design", image: reception1 },
    // Add more items as needed
  ];
  // Function to handle smooth scrolling to the Services section
  const scrollToServices = () => {
    const servicesSection = document.getElementById("services");
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="text-[#5a6137]">
      <div className="grid grid-cols-1 max-h-[80vh]">
        <SectionOneSwiper items={items} />
      </div>
      <div className="grid grid-cols-1 relative max-h-[85vh]" id="services">
        {" "}
        <div className="col-span-1 p-4 relative">
          {/* <div className="h-[40vh] w-[60vw] shadow-2xl rounded-2xl absolute top-20 right-20 ltr:left-20 bg-30-percent text-white grid">
            
          </div> */}
          <ImageVideoPreview t={t} imageSrc={kitchen1} videoSrc={video1} />
        </div>
        <div className="col-span-1 bg-secondary h-[100px] w-[100px] lg:w-[300px] lg:h-[300px] absolute -top-24 rtl:left-20 ltr:right-20 shadow-xl rounded-2xl max-sm:hidden">
          <h2 className="text-center text-white text-3xl">Services</h2>
          {imageLoaded ? (
            <img
              src={reception1}
              alt="Loaded"
              className="w-[260px] h-[260px] object-cover m-auto shadow-xl   p-4 max-h-[250px]"
            />
          ) : (
            <p>{t("loadingImage")}</p>
          )}
        </div>
      </div>
      <div
        className="grid grid-cols-1  relative  bg-primary"
        id="last-projects"
      >
        <Packages />
      </div>
      <div
        className="grid min-xl:grid-cols-2 min-lg:grid-cols-2  max-sm:grid-cols-1 relative h-[85vh] max-md:grid-cols-1  bg-30-percent"
        id="last-projects"
      >
        <SwipeIn />

        {/* <GridLayout /> */}
      </div>
      <DiscoverSection />
      <GuaranteeSection />
     <ResponsiveComponent /> 
    </div>
  );
};

export default Home;
