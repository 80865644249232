import { useState, useRef } from "react";

interface ImageVideoPreviewProps {
  imageSrc: string;
  videoSrc: string;
  t: (key: string) => string;
}

const ImageVideoPreview: React.FC<ImageVideoPreviewProps> = ({
  imageSrc,
  videoSrc,
  t,
}) => {
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handlePlayVideo = () => {
    setVideoPlaying(true);
    videoRef.current?.play();
  };

  const handleVideoEnd = () => {
    setVideoPlaying(false);
  };

  const handleVideoPause = () => {
    if (videoRef.current && videoRef.current.paused) {
      setVideoPlaying(false);
    }
  };

  return (
    <div className="relative w-full lg:max-h-[80vh] h-auto">
      {videoPlaying ? (
        <video
          ref={videoRef}
          src={videoSrc}
          controls
          autoPlay
          onEnded={handleVideoEnd}
          onPause={handleVideoPause}
          className="w-full h-auto object-cover lg:max-h-[80vh]"
        />
      ) : (
        <>
          <img
            src={imageSrc}
            alt="Loaded"
            className="w-full h-auto object-cover lg:max-h-[80vh]"
          />
          <button
            onClick={handlePlayVideo}
            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white font-bold text-2xl"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-28 hover:text-30-percent duration-300 ease-in-out ">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z" />
</svg>

          </button>
        </>
      )}
    </div>
  );
};

export default ImageVideoPreview;
