// AnimatedComponentAbout.tsx
import React, { useEffect, useState } from 'react';
import './AnimatedComponentAbout.css'; // Import CSS file for animations
import { reception1, reception2, toilet2, toilet3, toilet4, toilet5, toilet6, toilet7 } from '../images';

const AnimatedComponentAbout: React.FC = () => {
  const [isDropped, setIsDropped] = useState(false);

  useEffect(() => {
    // Trigger the drop animation after 1 second delay
    const timer = setTimeout(() => setIsDropped(true), 1000); // Start animation after 1s delay
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="absolute left-24 bottom-20 flex flex-col items-center justify-center h-fit w-fit max-sm:left-2">
      {/* Top Container: List of Avatars */}
      <div className="flex space-x-4 mb-4 border-b-2 w-fit pb-3">
        {/* Avatar images wrapped in container for animation */}
        <div className={`avatar-container object-cover ${isDropped ? 'drop' : ''}`}>
          <img src={reception1} alt="Avatar 1" className="avatar" />
        </div>
        <div className={`avatar-container object-cover ${isDropped ? 'drop' : ''}`}>
          <img src={reception2} alt="Avatar 2" className="avatar" />
        </div>
        <div className={`avatar-container object-cover ${isDropped ? 'drop' : ''}`}>
          <img src={toilet3} alt="Avatar 3" className="avatar" />
        </div>
        <div className={`avatar-container object-cover ${isDropped ? 'drop' : ''}`}>
          <img src={toilet4} alt="Avatar 3" className="avatar" />
        </div>
        {/* <div className={`avatar-container object-cover ${isDropped ? 'drop' : ''}`}>
          <img src={toilet5} alt="Avatar 3" className="avatar" />
        </div>
        <div className={`avatar-container object-cover ${isDropped ? 'drop' : ''}`}>
          <img src={toilet6} alt="Avatar 3" className="avatar" />
        </div>
        <div className={`avatar-container object-cover ${isDropped ? 'drop' : ''}`}>
          <img src={toilet7} alt="Avatar 3" className="avatar" />
        </div> */}
      </div>

      {/* Bottom Container */}
      <div className={`bottom-container  ${isDropped ? 'fade-in' : ''} text-right w-full `}>
        <h1 className="text-xl font-bold">خبرة كبيره ب مجال التشطيبات</h1>
      </div>
    </div>
  );
};

export default AnimatedComponentAbout;
