import React, { useState, ChangeEvent, FormEvent } from "react";
import { FaWhatsapp, FaPhone, FaFacebookF, FaInstagram } from "react-icons/fa";

interface FormData {
  name: string;
  phone: string;
  message: string;
}

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Here you could implement form submission logic or validation
    alert("Message sent successfully!");
    setFormData({ name: "", phone: "", message: "" });
  };

  return (
    <div className="py-12 mt-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Get in Touch</h2>
            <p className="text-gray-600 mb-8">
              Have a question or want to learn more about our products? Don't
              hesitate to reach out!
            </p>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  rows={4}
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition duration-300"
              >
                Send Message
              </button>
            </form>
          </div>
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Contact Us</h2>
            <div className="space-y-4">
              <a
                href="tel:+201097953956"
                className="flex items-center text-gray-600 hover:text-indigo-500"
              >
                <FaPhone className="h-6 w-6 mr-2" />
                +20 109 795 3956
              </a>
              <a
                href="https://wa.me/+201097953956"
                className="flex items-center text-gray-600 hover:text-indigo-500"
              >
                <FaWhatsapp className="h-6 w-6 mr-2" />
                +20 109 795 3956
              </a>
              <a
                href="https://www.facebook.com/SupportConstruction"
                className="flex items-center text-gray-600 hover:text-indigo-500"
              >
                <FaFacebookF className="h-6 w-6 mr-2" />
                Support Construction
              </a>
              <a
                href="https://www.instagram.com/support_construction"
                className="flex items-center text-gray-600 hover:text-indigo-500"
              >
                <FaInstagram className="h-6 w-6 mr-2" />
                @support_construction
              </a>
            </div>
            <div className="typewriter mt-6">
              <div className="slide">
                <i></i>
              </div>
              <div className="paper"></div>
              <div className="keyboard"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
