import React, { useEffect, useState } from 'react';

const WordAnimation: React.FC = () => {
  const [animationName, setAnimationName] = useState<string>('');
  const [startSecondAnimation, setStartSecondAnimation] = useState(false);
  const text = "support-constructions";
  const firstChar = text.charAt(0).toUpperCase(); // Capitalize the first character
  const remainingChars = text.slice(1); // Remaining characters
  const characters = [firstChar, ...Array.from(remainingChars)];

  useEffect(() => {
    // Randomly select one of four animations
    const animations = ['animation-one', 'animation-two', 'animation-three', 'animation-four'];
    const randomAnimation = animations[Math.floor(Math.random() * animations.length)];
    setAnimationName(randomAnimation);

    // Trigger second animation after 6 seconds
    const timer = setTimeout(() => {
      setStartSecondAnimation(true);
    }, 6000); // Adjust this duration to match the length of your initial animation

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative font-montserrat text-[1.7rem] md:text-xl lg:text-2xl inline-flex capitalize flex-wrap justify-center ltr">
      {characters.map((char, i) => {
        const randomScale = Math.random() * 1.5 + 0.5; // Random scale between 0.5 and 2
        const randomRotate = Math.random() * 360; // Random rotation between 0 and 360 degrees
        const randomDuration = Math.random() * 1 + 0.5; // Random duration between 0.5 and 1.5 seconds

        return (
          <div
            key={i}
            className="inline-block m-1 opacity-0 animate-[text-animation_1s_ease-in-out] duration-[${i * 100}ms] animate-[color-move-one_2s_ease-in-out_infinite] transform"
            style={{
              animation: startSecondAnimation
                ? `${animationName} 1s ease-in-out ${i * 100}ms forwards, ${startSecondAnimation ? 'color-move-two' : 'color-move-one'} 2s ease-in-out ${i * 100}ms infinite`
                : `text-animation ${randomDuration}s ease-in-out ${i * 100}ms forwards, color-move-one 2s ease-in-out ${i * 100}ms infinite`,
              transform: `scale(${randomScale}) rotate(${randomRotate}deg)`,
            }}
          >
            {char}
            <style>
              {`
                @keyframes text-animation {
                  0% { transform: scale(0) rotate(-20deg); opacity: 0; }
                  50% { transform: scale(1.5) rotate(0); opacity: 1; }
                  100% { transform: scale(1) rotate(0); opacity: 1; }
                }

                @keyframes animation-one {
                  0% { transform: scale(0) rotate(-20deg); opacity: 0; }
                  50% { transform: scale(1.2) rotate(90deg); opacity: 1; }
                  100% { transform: scale(1) rotate(0); opacity: 1; }
                }

                @keyframes animation-two {
                  0% { transform: scale(1) rotate(0); opacity: 0; }
                  50% { transform: scale(1.5) rotate(45deg); opacity: 1; }
                  100% { transform: scale(1) rotate(0); opacity: 1; }
                }

                @keyframes animation-three {
                  0% { transform: scale(1) rotate(0); opacity: 0; }
                  50% { transform: scale(1.5) rotate(-90deg); opacity: 0.5; }
                  100% { transform: scale(1) rotate(0); opacity: 1; }
                }

                @keyframes animation-four {
                  0% { transform: scale(1) rotate(0); opacity: 0; }
                  50% { transform: scale(1.5) rotate(180deg); opacity: 0.5; }
                  100% { transform: scale(1) rotate(360deg); opacity: 1; }
                }

                @keyframes color-move-one {
                  0% { color: #f8f7e6; }
                  50% { color: #30-percent; }
                  100% { color: #f8f7e6; }
                }

                @keyframes color-move-two {
                  0% { color: #30-percent; }
                  50% { color: #f8f7e6; }
                  100% { color: #30-percent; }
                }
              `}
            </style>
          </div>
        );
      })}
    </div>
  );
};

export default WordAnimation;
