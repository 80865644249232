import React, { useEffect, useState } from "react";

const AnimatedContent: React.FC = () => {
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    // Change content after 5 seconds
    const timer = setTimeout(() => {
      setShowPreview(true);
    }, 5000); // Adjust this duration as needed

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="container mx-auto p-4 text-center">
      <p
        className={`text-lg capitalize font-semibold text-[#f8f7e6] text-right ${
          showPreview
            ? "animate-content-fade-out"
            : "animate-content-slide-in delay-700"
        }`}
      >
        {showPreview
          ? "يمكنك الاستمتاع بمعاينة معرضنا!"
          : "اكتشف فن التشطيب بأعلى معايير الجودة والابتكار. نقدم حلولاً متميزة للتصميم الداخلي والخارجي، مع تشطيبات راقية للشقق والفلل تضفي لمسة من الفخامة والتميز على مساحتك."}
      </p>

      <div className="flex justify-start gap-4 mt-6  flex-wrap">
        <a href="#last-projects">
          <button className="text-[#f8f7e6] border-white border-2 py-3 px-6 hover:border-primary transition duration-300 ease-in-out animate-button-slide-up">
            اكتشف باقات التشطيب الآن
          </button>
        </a>
        {showPreview && (
          <a href="#gallery-preview">
            <button className="text-[#f8f7e6] border-white border-2 py-3 px-6 hover:border-primary transition duration-300 ease-in-out animate-button-slide-up">
              معاينة المعرض
            </button>
          </a>
        )}
      </div>

      <style>
        {`
          @keyframes content-slide-in {
            0% { transform: translateY(20px); opacity: 0; }
            100% { transform: translateY(0); opacity: 1; }
          }

          @keyframes content-fade-out {
            0% { opacity: 1; }
            100% { opacity: 0; }
          }

          @keyframes button-slide-up {
            0% { transform: translateY(100px); opacity: 0; }
            100% { transform: translateY(0); opacity: 1; }
          }

          .animate-content-slide-in {
            animation: content-slide-in 1s ease-out;
          }

          .animate-content-fade-out {
            animation: content-fade-out 1s ease-out;
          }

          .animate-button-slide-up {
            animation: button-slide-up 1s ease-out;
          }
        `}
      </style>
    </div>
  );
};

export default AnimatedContent;
