import React, { useState, useEffect } from "react";
import MyPackages from "./Package";
import PackageSection from "./Package";
import WordAnimation from "./WordAnimation";
import { MyPackagedDataLocal, PackageData } from "./MyPackagedDataLocal";


const initialPackages = MyPackagedDataLocal

const Packages: React.FC = () => {
  const [packages, setPackages] = useState<PackageData[]>(initialPackages);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await fetch("https://api.example.com/packages");
        if (!response.ok) {
          throw new Error("Failed to fetch packages");
        }
        const data: PackageData[] = await response.json();
        if (data.length > 0) {
          setPackages(data);
        }
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  //   if (error) {
  //     return <div>Error: {error}</div>;
  //   }

  return (
    <div className="bg-white py-24 sm:py-32 mx-6 ">
      <div className="  px-6 lg:px-8 ">
        <div className="sm:text-center    mx-12 max-sm:mx-0 ">
          
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl rtl w-full ">
            أسعار باقات التشطيب 
          </h2>
          <WordAnimation />
          <p className="mt-6 text-lg leading-8 text-gray-600 rtl">
            تفتخر شركة Support Constructions بتقديم باقات التشطيب المميزة بأسعار
            تنافسية لعام 2024، التي تناسب جميع الفئات السعريّة. يمكنك الآن
            الاطلاع على تكلفة تشطيب الفيلات والشقق في مصر من خلال سعر متر
            التشطيب الموضح في الباقات أدناه. نقدم لك تصميمات عصرية تتماشى مع
            ذوقك الخاص، مع ضمان جودة عالية وسعر منافس. نلتزم بمواعيد التسليم
            المتفق عليها، ونستخدم خامات متطورة وفقًا للعقد الملزم بيننا وبين
            العميل لضمان تنفيذ المشروع وفق التصميم المعتمد وحماية حقوق الطرفين.
          </p>
        </div>

        <PackageSection packages={packages} />
      </div>
    </div>
  );
};

export default Packages;
