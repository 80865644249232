import React, { useEffect, useRef } from "react";
import "../utilies/AboutUsPage.css"; // Import the CSS file for animations
import { reception1, reception2, reception4, toilet1, toilet2, toilet3 } from "../images";
import WordAnimation from "../utilies/WordAnimation";
import AnimatedText from "../utilies/AnimatedText";
import AnimatedComponentAbout from "../utilies/AnimatedComponentAbout";
import RotatingImagePreview from "../utilies/RotatingImagePreview";
import OurMissionSection from "../utilies/OurMissionSection";
import OurTeam from "../utilies/OurTeam";

const AboutUsPage: React.FC = () => {
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in-visible");
            observer.unobserve(entry.target); // Unobserve to avoid re-triggering the animation
          }
        });
      },
      { threshold: 0.3 } // Trigger when 30% of the section is visible
    );

    sectionRefs.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionRefs.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <div className="min-h-screen bg-secondary w-screen">
      {/* First Section */}
      <div
        ref={(el) => (sectionRefs.current[0] = el)}
        className="fade-in-hidden flex items-center justify-center w-screen relative transition-all duration-[2000ms]"
      >
        <div className="transition-transform duration-[3000ms] object-cover">
          <img
            src={reception4}
            alt="Centered Image"
            className="h-[100vh] w-[100vw] object-cover"
          />
          <h1 className="absolute top-20 left-30 text-white text-5xl font-bold p-8">
            <WordAnimation />
          </h1>
          <h1 className="absolute bottom-20 right-20 text-white text-5xl font-bold p-8">
            <AnimatedText text1="من نحن" text2="" />
          </h1>
        </div>
        <AnimatedComponentAbout />
      </div>

      {/* Second Section */}
      <div ref={(el) => (sectionRefs.current[1] = el)} className="fade-in-hidden">
        <RotatingImagePreview
          images={[reception2, reception4, reception1, toilet1, toilet2, toilet3]}
          rotationDuration={4}
          names={[
            "فيلا بالرحاب",
            "فيلا بمدينتي",
            "فيلا بمدينتي",
            "فيلا بمدينتي",
            "فيلا بمدينتي",
            "فيلا بالتجمع الخامس",
          ]}
        />
      </div>

      {/* Third Section */}
      <div ref={(el) => (sectionRefs.current[2] = el)} className="fade-in-hidden">
        <OurMissionSection />
      </div>

      {/* Fourth Section */}
      <div ref={(el) => (sectionRefs.current[3] = el)} className="fade-in-hidden">
        <OurTeam />
      </div>
    </div>
  );
};

export default AboutUsPage;
