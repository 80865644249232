import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaBuilding, FaCity, FaPhone, FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';

const NavBar: React.FC = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="p-4 flex justify-between items-center shadow-md text-[#dcdcdc] ltr:flex-row-reverse fixed w-full z-50 top-0 start-0 bg-[#eeeeee2c]">
      <div className="flex items-center space-x-4">
        <button
          className="md:hidden p-2 focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6 text-[#dcdcdc]"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
        <Link to="/" className="hidden md:flex items-center px-3 py-2 rounded relative transition duration-200 group">
          <FaHome className="w-6 h-6 mr-2 text-[#dcdcdc]" />
          <span className="text-[#dcdcdc]">الرئيسية</span>
          <span className="absolute inset-0 border-b-2 border-transparent transition duration-500 ease-out transform group-hover:from-center-bottom-to-top"></span>
        </Link>
        <Link to="/services/villa" className="hidden md:flex items-center px-3 py-2 rounded relative transition duration-200 group">
          <FaBuilding className="w-6 h-6 mr-2 text-[#dcdcdc]" />
          <span className="text-[#dcdcdc]">فيلا</span>
          <span className="absolute inset-0 border-b-2 border-transparent transition duration-500 ease-out transform group-hover:from-center-bottom-to-top"></span>
        </Link>
        <Link to="/services/duplex" className="hidden md:flex items-center px-3 py-2 rounded relative transition duration-200 group">
          <FaCity className="w-6 h-6 mr-2 text-[#dcdcdc]" />
          <span className="text-[#dcdcdc]">دوبلكس</span>
          <span className="absolute inset-0 border-b-2 border-transparent transition duration-500 ease-out transform group-hover:from-center-bottom-to-top"></span>
        </Link>
        <Link to="/services/apartment" className="hidden md:flex items-center px-3 py-2 rounded relative transition duration-200 group">
          <FaBuilding className="w-6 h-6 mr-2 text-[#dcdcdc]" />
          <span className="text-[#dcdcdc]">شقة</span>
          <span className="absolute inset-0 border-b-2 border-transparent transition duration-500 ease-out transform group-hover:from-center-bottom-to-top"></span>
        </Link>
        <Link to="/about" className="hidden md:flex items-center px-3 py-2 rounded relative transition duration-200 group">
          <FaBuilding className="w-6 h-6 mr-2 text-[#dcdcdc]" />
          <span className="text-[#dcdcdc]">عن الشركة</span>
          <span className="absolute inset-0 border-b-2 border-transparent transition duration-500 ease-out transform group-hover:from-center-bottom-to-top"></span>
        </Link>
        <Link to="/contact" className="hidden md:flex items-center px-3 py-2 rounded relative transition duration-200 group">
          <FaPhone className="w-6 h-6 mr-2 text-[#dcdcdc]" />
          <span className="text-[#dcdcdc]">اتصل بنا</span>
          <span className="absolute inset-0 border-b-2 border-transparent transition duration-500 ease-out transform group-hover:from-center-bottom-to-top"></span>
        </Link>
      </div>
      <div className="flex space-x-4 text-white gap-2">
        {user ? (
          <button
            onClick={logout}
            className="px-3 py-2 bg-secondary rounded relative transition duration-200 group"
          >
            <FaSignOutAlt className="w-6 h-6 mr-2 text-[#dcdcdc]" />
            <span className="text-[#dcdcdc]">تسجيل خروج</span>
            <span className="absolute inset-0 border-b-2 border-transparent transition duration-500 ease-out transform group-hover:from-center-bottom-to-top"></span>
          </button>
        ) : (
          <button
            onClick={() => navigate('/login')}
            className="px-3 py-2 bg-secondary rounded relative transition duration-200 group"
          >
            <FaSignInAlt className="w-6 h-6 mr-2 text-[#dcdcdc]" />
            <span className="text-[#dcdcdc]">تسجيل دخول</span>
            <span className="absolute inset-0 border-b-2 border-transparent transition duration-500 ease-out transform group-hover:from-center-bottom-to-top"></span>
          </button>
        )}
      </div>
      {menuOpen && (
        <div className="md:hidden absolute top-16 left-0 right-0 bg-white shadow-md z-10">
          <Link
            to="/"
            className="block px-3 py-2 text-[#dcdcdc] hover:bg-gray-100"
            onClick={toggleMenu}
          >
            الرئيسية
          </Link>
          <Link
            to="/services/villa"
            className="block px-3 py-2 text-[#dcdcdc] hover:bg-gray-100"
            onClick={toggleMenu}
          >
            فيلا
          </Link>
          <Link
            to="/services/duplex"
            className="block px-3 py-2 text-[#dcdcdc] hover:bg-gray-100"
            onClick={toggleMenu}
          >
            دوبلكس
          </Link>
          <Link
            to="/services/apartment"
            className="block px-3 py-2 text-[#dcdcdc] hover:bg-gray-100"
            onClick={toggleMenu}
          >
            شقة
          </Link>
          <Link
            to="/about"
            className="block px-3 py-2 text-[#dcdcdc] hover:bg-gray-100"
            onClick={toggleMenu}
          >
            عن الشركة
          </Link>
          <Link
            to="/contact"
            className="block px-3 py-2 text-[#dcdcdc] hover:bg-gray-100"
            onClick={toggleMenu}
          >
            اتصل بنا
          </Link>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
