import React from "react";
import { FaHardHat, FaTools, FaBuilding } from "react-icons/fa"; // Example icons from FontAwesome

interface PackageItem {
  item: string;
  itemsData: string[];
}

interface PackageData {
  id: number;
  name: string;
  description: string;
  salary: number;
  offer: string;
  items: PackageItem[];
}

interface PreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: PackageData | null;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ isOpen, onClose, data }) => {
  if (!isOpen || !data) return null;

  const iconMapping: Record<string, JSX.Element> = {
    "Hard Hat": <FaHardHat className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 text-purple-600" />,
    "Tools": <FaTools className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 text-purple-600" />,
    "Building": <FaBuilding className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 text-purple-600" />,
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ">
      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg max-w-lg w-full max-h-[80vh] overflow-y-auto max-sm:w-[80vw]">
        <h2 className="text-xl sm:text-2xl font-bold mb-4">Preview Package</h2>
        <div className="mb-4">
          <p className="text-gray-700 text-sm sm:text-base">Package Name: {data.name}</p>
          <p className="text-gray-700 text-sm sm:text-base">Offer: {data.offer}</p>
          <p className="text-gray-700 text-sm sm:text-base">Salary: EGP {data.salary}</p>
          <p className="text-gray-700 text-sm sm:text-base mb-4">Description: {data.description}</p>
          
          <div>
            {data.items.map((item, index) => (
              <div key={index} className="py-4 sm:py-6">
                <div className="relative group">
                  <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur-md opacity-25 group-hover:opacity-100 transition duration-500 ease-in-out"></div>
                  <div className="relative px-4 py-4 sm:px-6 sm:py-6 md:px-8 md:py-8 bg-white ring-1 ring-gray-900/5 rounded-lg flex items-center space-x-4 sm:space-x-6">
                    {iconMapping[item.item] || <FaTools className="w-6 h-6 min-w-6 min-h-6 max-w-6 max-h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 text-purple-600" />}
                    <div>
                      <p className="text-gray-800 text-sm sm:text-base md:text-lg font-medium rtl">{item.item}: <span className="text-sm "> {item.itemsData.join(', ')}</span></p>
                     
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          onClick={onClose}
          className="w-full bg-30-percent text-white py-2 sm:py-3 rounded hover:bg-secondary hover:text-black font-milk transition duration-200"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PreviewModal;
