import React, { useState } from 'react';
import PreviewModal from './PackageModal';

interface PackageData {
  id: number;
  name: string;
  description: string;
  salary: number;
  offer: string;
  items: { item: string; itemsData: string[] }[];
}

interface PackageSectionProps {
  packages: PackageData[];
}

const PackageSection: React.FC<PackageSectionProps> = ({ packages }) => {
  const [selectedPackage, setSelectedPackage] = useState<PackageData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePreviewClick = (pkg: PackageData) => {
    setSelectedPackage(pkg);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPackage(null);
  };

  return (
    <>
      <section className="flex flex-wrap justify-center gap-6 mt-12">
        {packages.map((pkg) => (
          <div
            key={pkg.id}
            className={`relative flex flex-col items-center p-8 rounded-xl shadow-lg transition-transform transform hover:scale-105
              ${pkg.salary <= 50 ? 'bg-gradient-to-l from-primary via-10-percent to-secondary' :
                pkg.salary <= 100 ? 'bg-gradient-to-r from-primary to-secondary' :
                'bg-gradient-to-r from-10-percent to-30-percent'}`}
          >
            <h6 className="text-xl font-bold mb-4 text-primary">{pkg.name}</h6>
            <div className="text-6xl font-bold relative mb-4">
              <span className="text-3xl text-primary">EGP</span>{pkg.salary}
            </div>
            <h5 className="text-lg font-semibold mb-2">{pkg.offer}</h5>
            <p className="text-center mb-4">{pkg.description}</p>

            <div
              onClick={() => handlePreviewClick(pkg)}
              className="absolute bottom-4 right-4 w-56 h-16 bg-white rounded-full flex items-center justify-center shadow-lg cursor-pointer transition-all hover:bg-gray-200"
            >
              <h3 className="text-black text-lg font-semibold">Preview</h3>
            </div>
          </div>
        ))}
      </section>

      {selectedPackage && (
        <PreviewModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          data={selectedPackage}
        />
      )}
    </>
  );
};

export default PackageSection;
