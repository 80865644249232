// BlockReveal.tsx
import React from 'react';
import './AnimatedText.css'; // Import the CSS file

interface BlockRevealProps {
  text1: string;
  text2: string;
  duration?: string; // Total duration for the animation
}

const BlockReveal: React.FC<BlockRevealProps> = ({ text1, text2, duration = '1.2s' }) => {
  return (
    <h1 className="block-effect">
      <div
        className="block-reveal"
        style={{ '--bc': '#4040bf', '--d': duration } as React.CSSProperties}
      >
        {text2} {' '}
        {text1} 
      </div>
      {/* <div
        className="block-reveal"
        style={{ '--bc': '#bf4060', '--d': duration } as React.CSSProperties}
      >
        {text2}
      </div> */}
    </h1>
  );
};

export default BlockReveal;
