import React from 'react';

const Profile: React.FC = () => {
  return (
    <div>
      <h1>Your Profile</h1>
      <p>Welcome to your profile page.</p>
    </div>
  );
};

export default Profile;
