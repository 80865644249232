import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import ProtectedRoute from './components/ProtectedRoute';
import ErrorPage from './components/ErrorPage';
import NotFoundPage from './components/NotFoundPage';
import Profile from './components/Profile';
import Contact from './components/Contact'; // Import Contact component
import Footer from './components/Footer';
import About from './components/About';
import Villa from './components/Services/Villa';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/profile"
          element={<ProtectedRoute element={<Profile />} />}
        />
        <Route
          path="/services/villa"
          element={<Villa />} 
        />
        <Route
          path="/services/duplex"
          element={<Villa />} 
        />
        <Route
          path="/services/apartment"
          element={<Villa />} 
        />
        <Route path="/contact" element={<Contact />} /> {/* Add route for Contact page */}
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
      <NavBar />
    <Footer />
    </Router>
  );
};

export default App;
