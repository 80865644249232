import React, { useState, useRef, useEffect } from "react";
import {
  reception1,
  reception2,
  reception3,
  reception4,
  toilet1,
  toilet2,
  toilet3,
  toilet4,
  kitchen1,
  kitchen2,
  bedroom1,
  bedroom2,
  hall1,
} from "../../images";
import {
  FaCommentDots,
  FaTimes,
  FaEye,
  FaArrowLeft,
  FaArrowRight,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

interface PreviewWorksProps {
  onRequestHelp: () => void;
  onRequestWork: () => void;
}

const items = [
  { title: "فيلا مدينتي", image: reception1, category: "construction" },
  { title: "فيلا مدينتي", image: reception2, category: "outdoor" },
  { title: "فيلا مدينتي", image: reception3, category: "reception" },
  { title: "فيلا التجمع الخامس", image: reception4, category: "reception" },
  { title: "فيلا التجمع الخامس", image: kitchen1, category: "kitchen" },
  { title: "فيلا التجمع الخامس", image: kitchen2, category: "kitchen" },
  { title: "فيلا اكتوبر", image: bedroom1, category: "bedroom" },
  { title: "فيلا اكتوبر", image: bedroom2, category: "bedroom" },
  { title: "فيلا اكتوبر", image: hall1, category: "hall" },
  //   { title: "Hall Design", image: hall2, category: "hall" },
  { title: "فيلا شارع التسعين", image: toilet1, category: "bathroom" },
  { title: "فيلا شارع التسعين", image: toilet2, category: "bathroom" },
  { title: "فيلا شارع التسعين", image: toilet3, category: "bathroom" },
  { title: "فيلا العاشر من رمضان", image: toilet4, category: "bathroom" },
  // Add more items as needed
];

const PreviewWorks: React.FC<PreviewWorksProps> = ({
  onRequestHelp,
  onRequestWork,
}) => {
  const [filter, setFilter] = useState<string>("all");
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const previewBarRef = useRef<HTMLDivElement>(null);

  const filteredItems =
    filter === "all" ? items : items.filter((item) => item.category === filter);

  const handleIconClick = () => {
    setIsHelpOpen(!isHelpOpen);
  };

  const openModal = (index: number) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentIndex((currentIndex + 1) % filteredItems.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (currentIndex - 1 + filteredItems.length) % filteredItems.length
    );
  };

  useEffect(() => {
    // Center the selected image in the preview bar
    if (previewBarRef.current) {
      const previewBar = previewBarRef.current;
      const selectedThumbnail = previewBar.children[
        currentIndex
      ] as HTMLElement;
      if (selectedThumbnail) {
        previewBar.scrollTo({
          left:
            selectedThumbnail.offsetLeft -
            previewBar.clientWidth / 2 +
            selectedThumbnail.clientWidth / 2,
          behavior: "smooth",
        });
      }
    }
  }, [currentIndex]);

  return (
    <div className="relative flex mt-52">
      {/* Fixed Help Icon */}
      <motion.button
        onClick={handleIconClick}
        animate={{ rotate: isHelpOpen ? 360 : 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className={`fixed bottom-4 left-4 lg:bottom-20 lg:left-4 p-2 rounded-full shadow-lg z-50 hover:scale-105 transition-transform  ${
          isHelpOpen ? "bg-blue-500 text-white" : "bg-green-500 text-white"
        }`}
      >
        {isHelpOpen ? (
          <FaTimes className="h-8 w-8" />
        ) : (
          <FaCommentDots className="h-8 w-8" />
        )}
      </motion.button>

      {/* Help Section */}
      {isHelpOpen && (
        <div className="p-4 w-screen rounded-r-lg shadow-md z-40 fixed left-2 right-2 top-20 h-screen bg-[#D57D7D60] flex justify-center items-center">
          <div className="p-4 bg-gray-100 w-auto rounded-r-lg shadow-2xl z-40 flex flex-col justify-center items-center rounded relative">
            <button
              onClick={handleIconClick}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <FaTimes className="h-6 w-6" />
            </button>
            <h3 className="text-xl font-bold mb-4">
              هل تحتاج إلى المساعدة أو تريد منا العمل على مشروعك؟
            </h3>
            <div className="space-x-4">
              <button
                onClick={onRequestHelp}
                className="bg-green-500 text-white py-2 px-6 rounded-md hover:bg-green-600"
              >
                أحتاج إلى المساعدة
              </button>
              <button
                onClick={onRequestWork}
                className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600"
              >
                أريدكم أن تعملوا على مشروعي
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="lg:ml-72 w-full p-4 overflow-auto h-screen">
        <h2 className="text-2xl font-bold mb-4 text-center">أعمالنا</h2>
        <p className="text-center">هنا يمكنك مشاهدة بعض من أعمالنا السابقة.</p>

        {/* Filter Options */}
        <div className="flex space-x-4 my-8 max-sm:w-[400px] max-sm:mx-auto overflow-auto">
          <button
            className={`py-2 px-4 rounded ${
              filter === "all" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setFilter("all")}
          >
            الكل
          </button>
          <button
            className={`py-2 px-4 rounded ${
              filter === "construction"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setFilter("construction")}
          >
            الإنشاءات
          </button>
          <button
            className={`py-2 px-4 rounded ${
              filter === "outdoor" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setFilter("outdoor")}
          >
            التصميم الخارجي
          </button>
          <button
            className={`py-2 px-4 rounded ${
              filter === "reception" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setFilter("reception")}
          >
            الاستقبال
          </button>
          <button
            className={`py-2 px-4 rounded ${
              filter === "kitchen" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setFilter("kitchen")}
          >
            المطبخ
          </button>
          <button
            className={`py-2 px-4 rounded ${
              filter === "bedroom" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setFilter("bedroom")}
          >
            غرفة النوم
          </button>
          <button
            className={`py-2 px-4 rounded ${
              filter === "hall" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setFilter("hall")}
          >
            الصالة
          </button>
          <button
            className={`py-2 px-4 rounded ${
              filter === "bathroom" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setFilter("bathroom")}
          >
            الحمام
          </button>
        </div>

        {/* Gallery */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredItems.map((item, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg p-4 relative"
            >
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-48 object-cover rounded-lg mb-4 mt-5"
              />
              <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
              <button
                onClick={() => openModal(index)}
                className="absolute top-2 left-2 text-gray-500 hover:text-gray-700"
              >
                <FaEye className="h-6 w-6" />
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
          >
            <div className="bg-white p-4 rounded-lg w-full max-w-2xl relative">
              <button
                onClick={closeModal}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                <FaTimes className="h-6 w-6" />
              </button>
              <img
                src={filteredItems[currentIndex].image}
                alt={filteredItems[currentIndex].title}
                className="w-full h-80 object-cover rounded-lg mb-4"
              />
              <h3 className="text-xl font-semibold mb-4">
                {filteredItems[currentIndex].title}
              </h3>
              <div className="flex justify-between">
                <button
                  onClick={prevImage}
                  className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                >
                  <FaArrowLeft className="inline" />
                </button>
                <button
                  onClick={nextImage}
                  className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                >
                  <FaArrowRight className="inline" />
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PreviewWorks;
