import React, { useEffect, useRef, useState } from "react";
import { siteEng, siteEng2, siteEng3, siteEngExperience, siteEngGirl, siteEngGirl2, siteEngGirl3 } from "../images";

const employees = [
  { name: 'أحمد القرشي', title: 'صاحب الشركة', projects: 120, experience: 15, image: siteEng, bgColor: 'bg-orange-500' },
  { name: 'مهندس ودكتور لبنى', title: 'مسؤول HR ومتابعة الأعمال', projects: 80, experience: 10, image: siteEngGirl2, bgColor: 'bg-teal-500' },
  { name: 'مهندس زياد', title: 'كبير مشرفين', projects: 95, experience: 12, image: siteEng2, bgColor: 'bg-purple-500' },
  { name: 'مهندسة سما', title: 'مدير مكتب فني', projects: 110, experience: 13, image: siteEngGirl, bgColor: 'bg-blue-500' },
  { name: 'مهندس ريوان', title: 'مسؤول تصميم 3D', projects: 70, experience: 8, image: siteEngGirl3, bgColor: 'bg-green-500' },
  { name: 'مهندس راشد', title: 'مدير قطاع', projects: 105, experience: 14, image: siteEngExperience, bgColor: 'bg-yellow-500' },
  { name: 'مهندس فايز', title: 'مدير قطاع', projects: 115, experience: 16, image: siteEng3, bgColor: 'bg-red-500' },
  { name: 'مهندس يوسف', title: 'مهندس', projects: 60, experience: 7, image: siteEng3, bgColor: 'bg-pink-500' },
  { name: 'مهندس عبدة', title: 'مهندس', projects: 50, experience: 6, image: siteEng, bgColor: 'bg-indigo-500' },
];

const OurTeam: React.FC = () => {
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]); // Ref array for each card
  const [isVisible, setIsVisible] = useState<boolean[]>(new Array(employees.length).fill(false));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = cardsRef.current.indexOf(entry.target as HTMLDivElement);
          if (entry.isIntersecting && index >= 0) {
            setIsVisible((prevState) => {
              const newState = [...prevState];
              newState[index] = true;
              return newState;
            });
            observer.unobserve(entry.target); // Unobserve after becoming visible
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    cardsRef.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardsRef.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  return (
    <section className="min-h-screen w-full bg-primary flex items-center justify-center overflow-y-auto text-black">
      <div className="p-8 text-center">
        <h2 className="text-4xl font-bold mb-8">فريق العمل لدينا</h2>
        <div className="p-24 flex flex-wrap items-center justify-center">
          {employees.map((employee, index) => (
            <div
              key={index}
              ref={(el) => (cardsRef.current[index] = el)}
              className={`flex-shrink-0 m-6 relative overflow-hidden rounded-lg max-w-xs shadow-lg ${employee.bgColor} p-6 transition-opacity duration-1000 ${
                isVisible[index] ? "opacity-100" : "opacity-0"
              }`}
            >
              <div className="relative flex flex-col items-center text-white">
                <div className="relative w-32 h-32 mb-4">
                  <img
                    className="w-full h-full object-cover rounded-full shadow-lg transition-transform transform hover:scale-105"
                    src={employee.image}
                    alt={employee.name}
                  />
                </div>
                <h3 className="text-xl font-semibold">{employee.name}</h3>
                <p className="text-md opacity-75">{employee.title}</p>
                <div className="mt-4">
                  <p className="text-sm">مشاريع مكتملة: {employee.projects}</p>
                  <p className="text-sm">سنوات الخبرة: {employee.experience} سنوات</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
