import React, { useState, useEffect } from "react";
import "./SectionOneSwiper.css";
import WordAnimation from "./WordAnimation";
import AnimatedContent from "./AnimatedContent";
interface CarouselItem {
  title: string;
  image: string;
}

interface SectionOneSwiperProps {
  items: CarouselItem[];
}

const SectionOneSwiper: React.FC<SectionOneSwiperProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically transition to the next slide
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [currentIndex]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="relative w-full h-[80VH] overflow-hidden bg-gray-900  ">
      {/* Main Image Container */}
      <div className="relative w-full h-full">
        {items.map((item, index) => (
          <div
            key={index}
            className={`absolute inset-0 w-full h-full bg-cover bg-center flex items-center justify-center transition-opacity duration-1000 ease-in-out ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
            style={{
              backgroundImage: `url(${item.image})`,
              filter: "brightness(0.75)",
            }}
          >
            {/* <span className="  font-bold text-white absolute left-32 top-60 text-[50px] capitalize ">{item.title}</span> */}
          </div>
        ))}
      </div>

      {/* Small Preview Container */}
      <div className="absolute bottom-8 right-8  overflow-hidden rounded-lg shadow-md border border-gray-700 p-4 scale-50 max-sm:hidden">
        <div
          className="flex  w-[800px] h-[300px] transition-transform duration-500 ease-in-out  "
          style={{
            transform: `translateX(-${(currentIndex * 100) / items.length}%)`,
          }}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className={`w-[300px] h-full bg-cover bg-center ${
                index === currentIndex
                  ? " opacity-100 ml-4 mr-3 scale-100 shadow-2xl rounded-2xl mt-0  duration-300 ease-in-out"
                  : "opacity-50 scale-75 mt-10"
              }`}
              style={{ backgroundImage: `url(${item.image})` }}
            ></div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={prevSlide}
        className="absolute top-1/2 transform -translate-y-1/2 left-4 bg-white text-gray-800 p-2 rounded-full shadow-lg hover:bg-gray-200"
      >
        {/* Prev */}
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 transform -translate-y-1/2 right-4 bg-white text-gray-800 p-2 rounded-full shadow-lg hover:bg-gray-200"
      >
        {/* Next */}
      </button>

      {/* Pagination Dots */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {items.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`h-3 w-3 rounded-full cursor-pointer ${
              index === currentIndex ? "bg-indigo-600" : "bg-gray-400"
            }`}
          />
        ))}
      </div>
      <div className="absolute inset-0 flex items-center justify-center z-50 rtl max-sm:mt-12 ">
        <div className=" border-2 backdrop-blur-sm shadow-2xl rounded-2xl text-right w-3/5 max-w-2xl h-auto p-8 max-sm:px-2 max-sm:py-2 flex flex-col justify-center gap-6 animate-fade-in">
          <h1 className="text-4xl font-bold capitalize  animate-slide-in max-sm:text-2xl text-center text-black">
          <WordAnimation />
          </h1>
          <h1 className="text-4xl font-bold capitalize text-[#f6f5d2] animate-slide-in max-sm:hidden ">
            تجربة تشطيب فريدة بأفكار إبداعية
          </h1>
          <AnimatedContent />
          
        </div>
      </div>
    </div>
  );
};

export default SectionOneSwiper;
