// src/data/footerData.ts

export interface FooterLink {
    title: string;
    links: { name: string; href: string }[];
  }
  
  export const footerData: FooterLink[] = [
    {
      title: 'Company',
      links: [
        { name: 'About', href: '#' },
        { name: 'Careers', href: '#' },
        { name: 'Brand Center', href: '#' },
        { name: 'Blog', href: '#' },
      ],
    },
    {
      title: 'Help Center',
      links: [
        { name: 'Discord Server', href: '#' },
        { name: 'Twitter', href: '#' },
        { name: 'Facebook', href: '#' },
        { name: 'Contact Us', href: '#' },
      ],
    },
    {
      title: 'Legal',
      links: [
        { name: 'Privacy Policy', href: '#' },
        { name: 'Licensing', href: '#' },
        { name: 'Terms & Conditions', href: '#' },
      ],
    },
    {
      title: 'Download',
      links: [
        { name: 'iOS', href: '#' },
        { name: 'Android', href: '#' },
        { name: 'Windows', href: '#' },
        { name: 'MacOS', href: '#' },
      ],
    },
  ];
  