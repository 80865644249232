export interface PackageData {
    id: number;
    name: string;
    description: string;
    salary: number;
    offer: string;
    items: { item: string; itemsData: string[] }[];
  }
export const MyPackagedDataLocal: PackageData[] = [
    {
      id: 1,
      name: "vip",
      description: "This is the elite package.",
      salary: 3800,
      offer: "",
      items: [
        {
          item: "الكهرباء",
          itemsData: [
            "سلك سويدي- علب ماجيك",
            "مفاتيح وبرايز فينوس بالضمان",
            "لوحة رايسية لاتزيد عن 36 خط",
            "ديفيتير للطرقة وكل الغرف",
            "2شاف طوارئ",
            "سبوتات وبيتنور في الطرقة ",
          ],
        },
        {
          item: "التيار الخفيف",
          itemsData: ["دايرة نت", "لوحة اتصالات", "تليفون للريسيبشن"],
        },
        {
          item: "الساوندسيستم",
          itemsData: ["ناسيس فقط سيستم سماعات للرسيبشن والمطبخ"],
        },
        { item: "الكاميرات", itemsData: ["سيستم خراطيم فقط"] },
        { item: "كابينة الشاور", itemsData: ["تاسيس مع قطوع ثابت"] },
        { item: "العزل", itemsData: ["رقبة زجاج زعزل الحمام سيكا 105"] },
        {
          item: "السباكة",
          itemsData: [
            "الصرف كاسل الماني",
            "التغذية BRالالمانية ضمن 15 سنة ",
            "الحمام سيفون خارج1800 وخلاط  - حوض مع وحدة ديكور لا يتعدسي 4000 ج - ",
            "الخلاط لايتعدي 1500 ج ",
          ],
        },
        {
          item: "الجبسونبورد",
          itemsData: [
            "للرسيبشن والطرقة والغرف الرئيسية ابيض بيت نور",
            "المطبخ فلات احمر مقاوم للحرارة ",
            "الحمام فلات اخضر مقاوم للرطوبة",
          ],
        },
        {
          item: "النقاشة",
          itemsData: [
            "وش سيلر مائي 3 سكينة معدون",
            "بطانية تلقيط بطانة 2 وش نهئي (نيون ربع لمعه قابل للغسيل)",
          ],
        },
        { item: "الاسقف", itemsData: ["مصيص للسقف 2 وش بلاستيك ابيض"] },
        {
          item: "الديكور",
          itemsData: [
            "جانب واحد في الرسيبشن",
            "جانب من كل غرفه دهانات او ورق حائط",
          ],
        },
        {
          item: "السيراميك",
          itemsData: [
            "الرسيبشن والممرات بورسلين 60*60 علي ان لا بتعدي سعر المتر 300 ج",
            "الحوائط كليوباترا او مايوازية  - فرز اول لا يتعدي سعر المتر 200 ج",
          ],
        },
        {
          item: "الرخام",
          itemsData: [
            "عتبة امام الشقة",
            "عتبة الشور نفس النوع",
            "المعابر لا يتعدي السعر 350 ج",
          ],
        },
        {
          item: "كرانيش بيوتك",
          itemsData: ["كرانيش للحمام والمطبخ 6 سم", "كرانيش للغرف لا يتعدي 9 سم"],
        },
        {
          item: "الاوميتال",
          itemsData: ["شبابيك المطبخ والحمام فقط قطاع ps صغير"],
        },
        {
          item: "الابواب الداخلية",
          itemsData: ["باب جاهز صيني  موديل اوكراني  "],
        },
        { item: "الباب الرئيسيي", itemsData: ["باب مصفح تركي 10سم عرض متر "] },
      ],
    },
    {
      id: 2,
      name: "luxury",
      description: "This is the elite package.",
      salary: 63000,
      offer: "",
      items: [
        {
          item: "الكهرباء",
          itemsData: [
            "سلك سويدي- علب ماجيك",
            "مفاتيح وبرايز فينوس بالضمان",
            "لوحة رايسية لاتزيد عن 36 خط",
            "ديفيتير للطرقة وكل الغرف",
            "7كشاف طوارئ",
            "سبوتات وبيتنور في الطرقة والريسيبشن وغرف النوم والحمامات والمطبخ",
            "انتركم مرئي احادي",
            "قاطع جهد زيادة امان",
            "2ابليك 2عين",
            "فانوس امام باب الشقة",
            "5م ليد بروفيل",
          ],
        },
        {
          item: "التيار الخفيف",
          itemsData: [
            "دايرة تليفون ودش كامل الوحدة - كابل HD- للريسيبشن فقط",
            "دايرة نت",
            "لوحة اتصالات",
            "تربل بلاي",
            "سمارتسيستم 4 مفاتيح",
          ],
        },
        {
          item: "الساوندسيستم",
          itemsData: [
            "توريد سيستم 4 سماعات الريسيبشن والمطبخ",
            "تاسيس باقي الغرف",
          ],
        },
        { item: "الكاميرات", itemsData: ["سيستم 2 كاميرا"] },
        { item: "كابينة الشاور", itemsData: ["كتتبينة الشور"] },
        { item: "العزل", itemsData: ["رقبة زجاج زعزل الحمام سيكا 107"] },
        {
          item: "السباكة",
          itemsData: [
            "الصرف اسمارت الماني",
            "التغذية BRالالمانية ضمن 15 سنة ",
            "الحمام سيفون مدفون وخلاط مدفون - حوض مع وحدة ديكور لا يتعدسي 6000 ج - رين شور 2",
            "الخلاط لايتعدي 2500 ج ",
            "طقم اكسسوارات لايتعدي 2000 ج ",
            "مرايا تاتش ليد للحمام هدية",
            "لينر للسيفون ",
            "شفاط للحمام ",
            "شفاط للمطبخ",
          ],
        },
        {
          item: "الجبسونبورد",
          itemsData: [
            "كامل الوحدة  +50م طقا للتصميم ",
            "للرسيبشن والطرقة والغرف الرئيسية ابيض بيت نور",
            "المطبخ فلات احمر مقاوم للحرارة ",
            "الحمام فلات اخضر مقاوم للرطوبة",
          ],
        },
        {
          item: "النقاشة",
          itemsData: [
            "مصيص للسقف 3 وش بلاستيك ابيض",
            "بطانية تلقيط بطانة 2 وش بلاستيك ابيض",
          ],
        },
        { item: "الاسقف", itemsData: ["مصيص للسقف 3 وش بلاستيك ابيض"] },
        {
          item: "الديكور",
          itemsData: [
            "حسب اختيار العميل م الدهانات او  ورق الحائط - جنب كل غرفة",
            "الرسيبشن جانب دهانات - تجليد بولي ماربل 2.8*2.4 مع 6 ستربات خشب وتجاليد سادة لاتتعدي 7 متر او حجر لايتعدي 10 متر",
          ],
        },
        {
          item: "السيراميك",
          itemsData: [
            "ارضيات الغرف سيراميك او  hdf علي ان لا ياعدي سعر النتر 500ج شامل سيراميك تحت hdf ",
            "الحوائط كليوباترا او مايوازية  - فرز اول لا يتعدي سعر المتر 300 ج",
          ],
        },
        {
          item: "الرخام",
          itemsData: [
            "الرسيبشن رخام مستورد (امبرادورلايت - بريبشيا اوما يوازيهم )",
            "عتبة امام الشقة",
            "عتبة الشور نفس النوع",
          ],
        },
        {
          item: "كرانيش بيوتك",
          itemsData: ["كرانيش للحمام والمطبخ 6 سم", "زوايا لسوك السيبشن والغرف "],
        },
        {
          item: "الاوميتال",
          itemsData: ["شبابيك المطبخ والحمام فقط قطاع ps صغير"],
        },
        { item: "الفريون", itemsData: ["حسب التصميم ولا يتعدي 15 م "] },
        {
          item: "الابواب الداخلية",
          itemsData: ["باب جاهز تركي اوكراني شامل الاكسسوارات  "],
        },
        { item: "الباب الرئيسيي", itemsData: ["باب مصفح تركي 11سم عرض متر "] },
      ],
    },
    {
      id: 2,
      name: "luxury",
      description: "This is the elite package.",
      salary: 63000,
      offer: "",
      items: [
        {
          item: "الكهرباء",
          itemsData: [
            "سلك سويدي- علب ماجيك",
            "مفاتيح وبرايز فينوس بالضمان",
            "لوحة رايسية لاتزيد عن 36 خط",
            "ديفيتير للطرقة وكل الغرف",
            "7كشاف طوارئ",
            "سبوتات وبيتنور في الطرقة والريسيبشن وغرف النوم والحمامات والمطبخ",
            "انتركم مرئي احادي",
            "قاطع جهد زيادة امان",
            "2ابليك 2عين",
            "فانوس امام باب الشقة",
            "5م ليد بروفيل",
          ],
        },
        {
          item: "التيار الخفيف",
          itemsData: [
            "دايرة تليفون ودش كامل الوحدة - كابل HD- للريسيبشن فقط",
            "دايرة نت",
            "لوحة اتصالات",
            "تربل بلاي",
            "سمارتسيستم 4 مفاتيح",
          ],
        },
        {
          item: "الساوندسيستم",
          itemsData: [
            "توريد سيستم 4 سماعات الريسيبشن والمطبخ",
            "تاسيس باقي الغرف",
          ],
        },
        { item: "الكاميرات", itemsData: ["سيستم 2 كاميرا"] },
        { item: "كابينة الشاور", itemsData: ["كتتبينة الشور"] },
        { item: "العزل", itemsData: ["رقبة زجاج زعزل الحمام سيكا 107"] },
        {
          item: "السباكة",
          itemsData: [
            "الصرف اسمارت الماني",
            "التغذية BRالالمانية ضمن 15 سنة ",
            "الحمام سيفون مدفون وخلاط مدفون - حوض مع وحدة ديكور لا يتعدسي 6000 ج - رين شور 2",
            "الخلاط لايتعدي 2500 ج ",
            "طقم اكسسوارات لايتعدي 2000 ج ",
            "مرايا تاتش ليد للحمام هدية",
            "لينر للسيفون ",
            "شفاط للحمام ",
            "شفاط للمطبخ",
          ],
        },
        {
          item: "الجبسونبورد",
          itemsData: [
            "كامل الوحدة  +50م طقا للتصميم ",
            "للرسيبشن والطرقة والغرف الرئيسية ابيض بيت نور",
            "المطبخ فلات احمر مقاوم للحرارة ",
            "الحمام فلات اخضر مقاوم للرطوبة",
          ],
        },
        {
          item: "النقاشة",
          itemsData: [
            "مصيص للسقف 3 وش بلاستيك ابيض",
            "بطانية تلقيط بطانة 2 وش بلاستيك ابيض",
          ],
        },
        { item: "الاسقف", itemsData: ["مصيص للسقف 3 وش بلاستيك ابيض"] },
        {
          item: "الديكور",
          itemsData: [
            "حسب اختيار العميل م الدهانات او  ورق الحائط - جنب كل غرفة",
            "الرسيبشن جانب دهانات - تجليد بولي ماربل 2.8*2.4 مع 6 ستربات خشب وتجاليد سادة لاتتعدي 7 متر او حجر لايتعدي 10 متر",
          ],
        },
        {
          item: "السيراميك",
          itemsData: [
            "ارضيات الغرف سيراميك او  hdf علي ان لا ياعدي سعر النتر 500ج شامل سيراميك تحت hdf ",
            "الحوائط كليوباترا او مايوازية  - فرز اول لا يتعدي سعر المتر 300 ج",
          ],
        },
        {
          item: "الرخام",
          itemsData: [
            "الرسيبشن رخام مستورد (امبرادورلايت - بريبشيا اوما يوازيهم )",
            "عتبة امام الشقة",
            "عتبة الشور نفس النوع",
          ],
        },
        {
          item: "كرانيش بيوتك",
          itemsData: ["كرانيش للحمام والمطبخ 6 سم", "زوايا لسوك السيبشن والغرف "],
        },
        {
          item: "الاوميتال",
          itemsData: ["شبابيك المطبخ والحمام فقط قطاع ps صغير"],
        },
        { item: "الفريون", itemsData: ["حسب التصميم ولا يتعدي 15 م "] },
        {
          item: "الابواب الداخلية",
          itemsData: ["باب جاهز تركي اوكراني شامل الاكسسوارات  "],
        },
        { item: "الباب الرئيسيي", itemsData: ["باب مصفح تركي 11سم عرض متر "] },
      ],
    },
    {
      id: 3,
      name: "Elite",
      description: "This is the elite package.",
      salary: 63000,
      offer: "",
      items: [
        {
          item: "الكهرباء",
          itemsData: [
            "سلك سويدي- علب ماجيك",
            "مفاتيح وبرايز فينوس بالضمان",
            "لوحة رايسية لاتزيد عن 36 خط",
            "ديفيتير للطرقة وكل الغرف",
            "7كشاف طوارئ",
            "سبوتات وبيتنور في الطرقة والريسيبشن وغرف النوم والحمامات والمطبخ",
            "انتركم مرئي احادي",
            "قاطع جهد زيادة امان",
            "2ابليك 2عين",
            "فانوس امام باب الشقة",
            "5م ليد بروفيل",
          ],
        },
        {
          item: "التيار الخفيف",
          itemsData: [
            "دايرة تليفون ودش كامل الوحدة - كابل HD- للريسيبشن فقط",
            "دايرة نت",
            "لوحة اتصالات",
            "تربل بلاي",
            "سمارتسيستم 4 مفاتيح",
          ],
        },
        {
          item: "الساوندسيستم",
          itemsData: [
            "توريد سيستم 4 سماعات الريسيبشن والمطبخ",
            "تاسيس باقي الغرف",
          ],
        },
        { item: "الكاميرات", itemsData: ["سيستم 2 كاميرا"] },
        { item: "كابينة الشاور", itemsData: ["كتتبينة الشور"] },
        { item: "العزل", itemsData: ["رقبة زجاج زعزل الحمام سيكا 107"] },
        {
          item: "السباكة",
          itemsData: [
            "الصرف اسمارت الماني",
            "التغذية BRالالمانية ضمن 15 سنة ",
            "الحمام سيفون مدفون وخلاط مدفون - حوض مع وحدة ديكور لا يتعدسي 6000 ج - رين شور 2",
            "الخلاط لايتعدي 2500 ج ",
            "طقم اكسسوارات لايتعدي 2000 ج ",
            "مرايا تاتش ليد للحمام هدية",
            "لينر للسيفون ",
            "شفاط للحمام ",
            "شفاط للمطبخ",
          ],
        },
        {
          item: "الجبسونبورد",
          itemsData: [
            "كامل الوحدة  +50م طقا للتصميم ",
            "للرسيبشن والطرقة والغرف الرئيسية ابيض بيت نور",
            "المطبخ فلات احمر مقاوم للحرارة ",
            "الحمام فلات اخضر مقاوم للرطوبة",
          ],
        },
        {
          item: "النقاشة",
          itemsData: [
            "مصيص للسقف 3 وش بلاستيك ابيض",
            "بطانية تلقيط بطانة 2 وش بلاستيك ابيض",
          ],
        },
        { item: "الاسقف", itemsData: ["مصيص للسقف 3 وش بلاستيك ابيض"] },
        {
          item: "الديكور",
          itemsData: [
            "حسب اختيار العميل م الدهانات او  ورق الحائط - جنب كل غرفة",
            "الرسيبشن جانب دهانات - تجليد بولي ماربل 2.8*2.4 مع 6 ستربات خشب وتجاليد سادة لاتتعدي 7 متر او حجر لايتعدي 10 متر",
          ],
        },
        {
          item: "السيراميك",
          itemsData: [
            "ارضيات الغرف سيراميك او  hdf علي ان لا ياعدي سعر النتر 500ج شامل سيراميك تحت hdf ",
            "الحوائط كليوباترا او مايوازية  - فرز اول لا يتعدي سعر المتر 300 ج",
          ],
        },
        {
          item: "الرخام",
          itemsData: [
            "الرسيبشن رخام مستورد (امبرادورلايت - بريبشيا اوما يوازيهم )",
            "عتبة امام الشقة",
            "عتبة الشور نفس النوع",
          ],
        },
        {
          item: "كرانيش بيوتك",
          itemsData: ["كرانيش للحمام والمطبخ 6 سم", "زوايا لسوك السيبشن والغرف "],
        },
        {
          item: "الاوميتال",
          itemsData: ["شبابيك المطبخ والحمام فقط قطاع ps صغير"],
        },
        { item: "الفريون", itemsData: ["حسب التصميم ولا يتعدي 15 م "] },
        {
          item: "الابواب الداخلية",
          itemsData: ["باب جاهز تركي اوكراني شامل الاكسسوارات  "],
        },
        { item: "الباب الرئيسيي", itemsData: ["باب مصفح تركي 11سم عرض متر "] },
      ],
    },
    // Add more packages as needed
  ];