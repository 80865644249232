import React from "react";
import SwipeIn from "./swopper";
import { FaHammer, FaBuilding, FaTape as FaTeam } from "react-icons/fa";
import { GiMagnifyingGlass } from "react-icons/gi";
import WordAnimation from "./WordAnimation";

const DiscoverSection: React.FC = () => {
  return (
    <section id="last-projects" className="relative h-screen bg-gray-100 rtl ">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 gap-4 h-full px-4 py-8">
        <div className="col-span-1 md:col-span-2 lg:col-span-4 flex flex-col items-center lg:items-start justify-center text-center lg:text-left">
          {/* <div className="text-gray-800 rtl text-3xl md:text-5xl lg:text-7xl font-bold transform rotate-[-90deg] lg:rotate-[-90deg] lg:translate-x-[-80%] md:rotate-0 w-full">
            اكتشف
          </div> */}
            <div className="text-gray-900 text-xl md:text-2xl lg:text-4xl font-semibold gap-4 bg-secondary px-4 py-2 rounded mt-4 flex items-center justify-center lg:justify-start w-full">
              <FaBuilding className="mr-2 text-2xl" />
              <WordAnimation />
            </div>
          <div className=" flex flex-wrap gap-4 rtl ">
            <div className="w-[300px] bg-30-percent  text-primary  rounded-xl  h-fit px-4 py-2 text-base md:text-lg lg:text-xl leading-relaxed mt-4 flex ">
              اكتشف لحظات البناء الرائعة لدينا.
              <GiMagnifyingGlass className="mx-1 text-xl w-12 h-12" />
            </div>
            <div className="w-[300px] bg-30-percent  text-primary  rounded-xl  h-fit px-4 py-2 text-base md:text-lg lg:text-xl leading-relaxed mt-4 flex">
              اكتشف أعمال البناء الجميلة التي نقدمها.
              <FaHammer className="mx-1 text-xl w-12 h-12" />
            </div>
            <div className="w-[300px] bg-30-percent  text-primary  rounded-xl  h-fit px-4 py-2 text-base md:text-lg lg:text-xl leading-relaxed mt-4 flex">
              اكتشف أعمال فريقنا المتميز.
              <FaTeam className="mx-1 text-xl w-12 h-12" />
            </div>
            <div className="w-[300px] bg-30-percent  text-primary  rounded-xl  h-fit px-4 py-2 text-base md:text-lg lg:text-xl leading-relaxed mt-4 flex">
              اكتشف قوة الشركات المنظمة معنا.
              <GiMagnifyingGlass className="mx-1 text-xl w-12 h-12" />
            </div>
          </div>
        </div>
        <div className="col-span-1 md:col-span-2 lg:col-span-3 flex items-center justify-center ">
          <div className="w-full h-full flex items-center justify-center ">
            <SwipeIn />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DiscoverSection;
